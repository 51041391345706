const config = {
  stripe: {
    apiKey:
      "pk_live_51HX30WEoKauBeTlcZE5vIK2PcO1iisGSqBpO4DZlIPlsE0I2NBtkIA0NyBvydpQAMSvcKtVyjPgxKFQNuiMPN2OK007Pgkdc3a",
    apiUrl:
      "https://szn63fg40f.execute-api.ap-southeast-2.amazonaws.com/dev/charges",
    currency: "AUD",
  },
};

export default config;
