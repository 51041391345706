import Layout from "./components/Layout";
import PayButton from "./components/PayButton";
import React from "react";
import CurrencyInput from "react-currency-input";
import { useAlert } from "react-alert";

const App = () => {
  const [val, setVal] = React.useState(1);
  const alert = useAlert();
  const handleChange = (event, maskedvalue, floatvalue) => {
    setVal(floatvalue);
  };

  const handleData = (message) => {
    if (message.error) {
      alert.error(message.error);
    } else {
      alert.success(
        message.message + " \n Amount : AUD" + message.charge.amount / 100.0
      );
    }
  };
  const calcAmount = (amount) => {
    return Math.ceil(amount * 1.029);
  };

  return (
    <Layout>
      <h1>Pay with your preferred card in a secure way.</h1>
      <CurrencyInput
        prefix="AUD "
        className="form-control"
        precision="2"
        value={val}
        onChangeEvent={handleChange}
      />
      <h6 style={{ color: "black", margin: 0, padding: 0, marginTop: 10 }}>
        Payments with Card will have an extra surcharge of 2.9%
      </h6>
      <h4></h4>
      {val <= 0 ? (
        <h4> Amount Invalid</h4>
      ) : (
        <PayButton amount={calcAmount(val * 100)} onDataFetched={handleData} />
      )}
    </Layout>
  );
};
export default App;
